#hidden {
    display: none;
}

.navbar {
    width: 100%;
    z-index: 999;
    left: 0;
    top: 0;
}

.navbar ul {
    display: flex;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    height: 100%;
    align-items: center;
}

.navbar ul li {
    list-style-type: none;
    padding: 10px 20px;
}

.navbar ul figure {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 10px;
    margin-inline-end: 0;
    cursor: pointer;
}