@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Oswald&family=Playfair+Display&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#hidden {
    display: none;
}

.navbar {
    width: 100%;
    z-index: 999;
    left: 0;
    top: 0;
}

.navbar ul {
    display: flex;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    height: 100%;
    align-items: center;
}

.navbar ul li {
    list-style-type: none;
    padding: 10px 20px;
}

.navbar ul figure {
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-margin-start: 10px;
            margin-inline-start: 10px;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
    cursor: pointer;
}
.footer-wrapper li {
    text-decoration: none;
    list-style-type: none;
}

.footer-wrapper {
    background-color: #f6f4f2;
}

@media screen and (min-width: 480px) {
    .footer-wrapper .left-side {
        text-align: left !important;
    }
}

textarea, input { 
    outline: none;
    box-shadow: none;
    border: none;
    color: white;
};

input:focus, textarea:focus, select:focus{
    outline: none;
}

.btn:focus {
    outline: none !important;
    outline-offset: none !important;
    box-shadow: none !important;
}

body {
    color: #4f4f4f !important;
    font-family: 'Montserrat', sans-serif !important;
    background-color: #f6f4f2;
    font-size: 13px !important;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Playfair Display', serif;
}

.decr-none {
    text-decoration: none;
    color: inherit !important;
}

.decr-none:hover {
    color: #dba860 !important;
    text-decoration: none !important;
}

.decr-none:focus {
    color: #dba860 !important;
    text-decoration: none !important;
}

.rounded-0 {
    border-width: 2px !important;
}

.bold-font {
    font-weight: bold;
}

.btn-gold {
    background-color: #dba860 !important;
    border-color: #dba860  !important;
    -webkit-appearance: none;
}

.btn-gold:hover {
    background-color: #b48a4f !important;
    border-color: #b48a4f  !important;
}

.nav-link {
    font-family: 'Oswald', sans-serif !important;
    letter-spacing: 0.2em;
    font-weight: bold;
    text-transform: uppercase;
}

.nav-link:hover {
    color: #b48a4f  !important;
}

.dropdown-item {
    font-family: 'Oswald', sans-serif !important;
    letter-spacing: 0.2em;
    font-weight: bold;
    text-transform: uppercase;
}

.dropdown-item:hover {
    color: #b48a4f  !important;
}

.no-border {
    outline: none;
    border: none;
    background-color: transparent !important;
}

.white-font {
    color: white !important;
}
