@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Oswald&family=Playfair+Display&display=swap');

textarea, input { 
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    color: white;
};

input:focus, textarea:focus, select:focus{
    outline: none;
}

.btn:focus {
    outline: none !important;
    outline-offset: none !important;
    box-shadow: none !important;
}

body {
    color: #4f4f4f !important;
    font-family: 'Montserrat', sans-serif !important;
    background-color: #f6f4f2;
    font-size: 13px !important;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Playfair Display', serif;
}

.decr-none {
    text-decoration: none;
    color: inherit !important;
}

.decr-none:hover {
    color: #dba860 !important;
    text-decoration: none !important;
}

.decr-none:focus {
    color: #dba860 !important;
    text-decoration: none !important;
}

.rounded-0 {
    border-width: 2px !important;
}

.bold-font {
    font-weight: bold;
}

.btn-gold {
    background-color: #dba860 !important;
    border-color: #dba860  !important;
    -webkit-appearance: none;
}

.btn-gold:hover {
    background-color: #b48a4f !important;
    border-color: #b48a4f  !important;
}

.nav-link {
    font-family: 'Oswald', sans-serif !important;
    letter-spacing: 0.2em;
    font-weight: bold;
    text-transform: uppercase;
}

.nav-link:hover {
    color: #b48a4f  !important;
}

.dropdown-item {
    font-family: 'Oswald', sans-serif !important;
    letter-spacing: 0.2em;
    font-weight: bold;
    text-transform: uppercase;
}

.dropdown-item:hover {
    color: #b48a4f  !important;
}

.no-border {
    outline: none;
    border: none;
    background-color: transparent !important;
}

.white-font {
    color: white !important;
}