.footer-wrapper li {
    text-decoration: none;
    list-style-type: none;
}

.footer-wrapper {
    background-color: #f6f4f2;
}

@media screen and (min-width: 480px) {
    .footer-wrapper .left-side {
        text-align: left !important;
    }
}
